'use strict';

/**
 * A simple form.
 *
 * @param action
 * @param method
 * @returns {Form}
 * @constructor
 */
function Form(action, method) {
    this.action = action;
    this.method = method;
    this.fields = [];
    this.__form = null;
    return this;
}

/**
 * A simple form field.
 *
 * @param name
 * @param value
 * @param type
 * @constructor
 */
function Field(name, value, type) {
    this.name = name;
    this.value = value;
    this.type = type;
}

/**
 * Add a field to the form.
 *
 * @param name
 * @param value
 * @param type
 * @returns {Form}
 */
Form.prototype.addField = function(name, value, type) {
    this.fields.push(new Field(name, value, type));
    this.__build();
    return this;
};

/**
 * Rebuild the form and set to the form object.
 *
 * @returns {Element}
 * @private
 */
Form.prototype.__build = function() {
    var f = document.createElement("form");

    f.setAttribute('method', this.method);
    f.setAttribute('action', this.action);

    for(var i = 0; i < this.fields.length; i++) {
        var tmp = document.createElement("input");
        tmp.setAttribute('type', this.fields[i].type);
        tmp.setAttribute('name', this.fields[i].name);
        tmp.setAttribute('value', this.fields[i].value);
        f.appendChild(tmp);
    }

    this.__form = f;
    document.body.appendChild(this.__form);
    return f;
};

/**
 * Override default submit of a form.
 */
Form.prototype.submit = function() {
    var csrfToken = document.querySelector("meta[name=_token]").getAttribute('content');

    if(csrfToken) {
        this.addField('_token', csrfToken, 'hidden');
    }

    this.__form.submit();
};