var QueryString = function () {
    var query_string = {};
    var query = window.location.search.substring(1);
    // Voor oude brouwsers support welke window.location.search niet kennen.
    if (query == "") {
        query = window.location.hash;
    }
    if (query.substring(0, 2) == "#?") {
        query = query.substring(2);
    }

    if (query.length > 0) {
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = pair[1];
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
                var arr = [query_string[pair[0]], pair[1]];
                query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
                query_string[pair[0]].push(pair[1]);
            }
        }
    }

    return query_string;
}();

function updateQueryStringParameters(key, value) {
    if (value == "") {
        // No FilterValue, remove whole filter from url
        delete QueryString[key];
        return;
    }

    for (var k in QueryString) {
        if (!QueryString.hasOwnProperty(k)) continue;
        if (k == key) {
            // Check if there is a value
            if (value == "") {
                // No FilterValue, remove whole filter from url
                delete QueryString[k];
            } else {
                // There is a FilterValue, add it.
                QueryString[k] = value;
            }
            return;
        }
    }
    // Key does not exist jet. Create it.
    QueryString[key] = value;
}

/**
 * Create uri parameters form querystring object.
 *
 * @returns {string}
 * @constructor
 */
function QueryStringObjectToParameters() {
    var string = "";
    for (var k in QueryString) {
        if (!QueryString.hasOwnProperty(k)) continue;

        string += "&" + k + "=" + QueryString[k];
    }

    string = string.substr(1);
    string = "?" + string;

    return string;
}